
@font-face {
  font-family: 'exo2-semibold';
  font-weight: normal;
  font-style : regular;
  src: url('../public/files/project/fonts/exo2-semibold-webfont.woff') format('woff'),
       url('../public/files/project/fonts/exo2-semibold-webfont.ttf') format('ttf');
}

@font-face {
  font-family: 'exo2-regular';
  font-weight: normal;
  font-style : regular;
  src: url('../public/files/project/fonts/exo2-regular-webfont.woff') format('woff'),
       url('../public/files/project/fonts/exo2-regular-webfont.ttf') format('ttf');
}


@font-face {
  font-family: 'projet';
  font-weight: normal;
  font-style : normal;
  src: url('../public/files/project/fonts/projet.woff') format('woff'),
       url('../public/files/project/fonts/projet.ttf') format('ttf');

}
@font-face {
  font-family: 'Helvetica-Bold';
  font-weight: bold;
  font-style : normal;
  src: url('../public/files/project/fonts/Helvetica-Bold.woff') format('woff'),
       url('../public/files/project/fonts/Helvetica-Bold.ttf') format('ttf');

}
@font-face {
  font-family: 'Helvetica';
  font-weight: normal;
  font-style : normal;
  src: url('../public/files/project/fonts/Helvetica.woff') format('woff'),
       url('../public/files/project/fonts/Helvetica.ttf') format('ttf');

}
@font-face {
  font-family: 'HelveticaNeue-Condensed';
  font-weight: normal;
  font-style : normal;
  src: url('../public/files/project/fonts/HelveticaNeue-Condensed.woff') format('woff'),
       url('../public/files/project/fonts/HelveticaNeue-Condensed.ttf') format('ttf');

}

.title-font,
.content-font {
  font-family: Arial, Helvetica, sans-serif;
}

.app-toolbar .content-font, .home-tile {
  font-family: Arial, Helvetica, sans-serif;
}

.icon-font {
  font-family: 'project';
}

input, .generic-input, ::-webkit-input-placeholder {
  @extend .content-font;
}